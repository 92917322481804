// extracted by mini-css-extract-plugin
export var benefits = "homeFeatures-module--benefits--3ec81";
export var col = "homeFeatures-module--col--a7ea2";
export var container = "homeFeatures-module--container--1984f";
export var featureLink = "homeFeatures-module--feature-link--521ac";
export var row = "homeFeatures-module--row--bc45f";
export var rowReverse = "homeFeatures-module--row-reverse--f6f1a";
export var subtitle = "homeFeatures-module--subtitle--56a21";
export var textCol = "homeFeatures-module--text-col--9b415";
export var title = "homeFeatures-module--title--bb1ee";
export var videoCol = "homeFeatures-module--video-col--4d778";
export var wavesYoutube = "homeFeatures-module--waves-youtube--c3878";