import * as React from "react";
import Container from "react-bootstrap/Container";
import Layout from "../components/layout";
import HomeFeatures from "../components/homePage/homeFeatures";
import HomeHero from "../components/homePage/HomeHero";
import Activation from "../components/activation";
import { container } from "./index.module.css";
import CallToAction from "../components/CallToAction";
import { SEO } from "../components/seo";
import SocialProof from "../components/homePage/socialProof";
import FeaturesSummary from "../components/productPage/featuresSummary";

const IndexPage = () => {
  return (
    // Container needed outside to cute off al the css shapes used that fall outside page
    <Container fluid className={container}>
      <Layout pageTitle="Improve you customer experience and streamline your operations">
        <HomeHero />
        <HomeFeatures />
        <CallToAction />
        <SocialProof />
        <CallToAction />
        <FeaturesSummary />
        <Activation
          title="Choose a better way to work"
          buttonText="Request a Demo"
        />

        {/* <NewsletterSignUp /> */}
      </Layout>
    </Container>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
