import * as React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  container,
  videoCol,
  col,
  textCol,
  title,
  subtitle,
  row,
  rowReverse,
  featureLink,
} from "./homeFeatures.module.css";
import MaintenanceVideo from "../../images/maintenance-home.mp4";
import GuestAppVideo from "../../images/guest-app-home.mp4";
import TeamCommunicationVideo from "../../images/team-communication.mp4";
import { StaticQuery } from "gatsby";
import { LazyVideo } from "../LazyVideo";

const Component = () => {
  return (
    <>
      <Container fluid className={container}>
        <Row className={row}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={col}>
            <LazyVideo className={videoCol} src={GuestAppVideo} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={textCol}>
            <h1 className={title}>
              Elevate every moment of your customers' journey
            </h1>
            <p className={subtitle}>
              Transform your guests' charter experience with the Floatist guest
              app, where they can effortlessly manage bookings, access essential
              yacht information, and handle all pre-arrival tasks from one
              convenient place.
            </p>
            <AnchorLink
              to="/features/guest-experience-app"
              className={featureLink}
            >
              Learn more about the guest application{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </AnchorLink>
          </Col>
        </Row>

        <Row className={rowReverse}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={textCol}>
            <h1 className={title}>Easily manage yacht maintenance</h1>
            <p className={subtitle}>
              Effortlessly manage your yacht's maintenance with Floatist's
              intuitive app, replacing cumbersome Excel sheets or outdated
              software with simple, easy to use task tracking and automated
              reminders on any device. 
            </p>
            <AnchorLink to="/features/maintenance" className={featureLink}>
              Learn more about maintenance{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </AnchorLink>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={col}>
            <LazyVideo className={videoCol} src={MaintenanceVideo} />
          </Col>
        </Row>

        <Row className={row}>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={col}>
            <LazyVideo className={videoCol} src={TeamCommunicationVideo} />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className={textCol}>
            <h1 className={title}>
              Streamline Operations with Seamless Team Communication
            </h1>
            <p className={subtitle}>
              Enhance efficiency and coordination across your team with
              Floatist's integrated communication tools, ensuring that every
              member is synchronized and informed in real-time.
            </p>
            <AnchorLink
              to="/features/charter-base-operations"
              className={featureLink}
            >
              Learn more about operations management{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </AnchorLink>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const HomeFeatures = (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Component data={data} {...props} />}
  />
);

export default HomeFeatures;

export const query = graphql`
  query {
    file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 400)
      }
    }
  }
`;
