// extracted by mini-css-extract-plugin
export var blue = "HomeHero-module--blue--c3a8b";
export var contactButton = "HomeHero-module--contact-button--73ad7";
export var heroSection = "HomeHero-module--hero-section--6fe52";
export var imageStyle = "HomeHero-module--image-style--cf535";
export var learnButton = "HomeHero-module--learn-button--cc038";
export var mixedColorsSection = "HomeHero-module--mixed-colors-section--564cf";
export var pageTitle = "HomeHero-module--page-title--3c906";
export var paragraph = "HomeHero-module--paragraph--0b62c";
export var text = "HomeHero-module--text--57a71";
export var video = "HomeHero-module--video--72918";
export var waves2 = "HomeHero-module--waves2--ea13d";