import { useRef, useEffect } from "react";

export const useLazyVideo = () => {
  const videoRef = useRef(null);

  const cb = (entries) => {
    if (videoRef.current) {
      const [entry] = entries;

      if (entry.isIntersecting) videoRef.current.play();
      else videoRef.current.pause();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(cb, {
      root: null, // use document bounds to observe the intersection
      threshold: 0.1,
      rootMargin: "0px", // element is visible
    });

    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, [videoRef]);

  return videoRef;
};
