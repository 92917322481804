import React from "react";
import { useLazyVideo } from "../hooks/use-lazy-video";

export const LazyVideo = ({ src, className, type = "video/mp4" }) => {
  const videoRef = useLazyVideo();

  return (
    <video ref={videoRef} className={className} muted loop>
      <source src={src} type={type} />
      Your browser does not support the video tag.
    </video>
  );
};
