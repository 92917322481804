import * as React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import CallToAction from "../CallToAction";
import PrimaryButton from "../../styles/styled-component/PrimaryButton";
import HeroVideo from "../../images/herovideonp.mp4";
import {
  pageTitle,
  mixedColorsSection,
  video,
  text,
  blue,
  paragraph,
  contactButton,
  heroSection,
} from "./HomeHero.module.css";
import LogoRow from "./logoRow";
import { LazyVideo } from "../LazyVideo";

const HomeHero = () => {
  return (
    <>
      <Container fluid className={heroSection}>
        <p className={paragraph}>
          The #1 Yacht, Fleet and Charter Management Solution for Yachting
          Professionals
        </p>
        <h1 className={pageTitle}>
          Made for Yachts. <span className={blue}>Built for Productivity.</span>
        </h1>
        <p className={text}>
          Ditch the pen and paper, spreadsheets, and outdated software. Floatist
          brings all your data together, providing a centralized, easy-to-use
          platform that simplifies every aspect of yacht management, from
          maintenance to customer interactions.{" "}
        </p>
        <PrimaryButton size="lg" className={contactButton}>
          <Link to="/contact">Request a Demo</Link>
        </PrimaryButton>
        <LogoRow />
      </Container>

      <Container fluid className={mixedColorsSection}>
        <LazyVideo className={video} src={HeroVideo} />
      </Container>

      <CallToAction backgroundColor="#f2f2f2" />
    </>
  );
};

export default HomeHero;
